import _ from 'lodash';

export type TextTransform = 'uppercase' | 'lowercase';

declare global {
	interface String {
		toSnakeCase(transform: TextTransform): string;
		in(...args: string[]): boolean;
		notIn(...args: string[]): boolean;
		equals(
			strToCompare: string,
			ignoreSpaces?: boolean,
			ignoreCase?: boolean
		): boolean;
		notEquals(
			strToCompare: string,
			ignoreSpaces: boolean,
			ignoreCase: boolean
		): boolean;
	}
}

String.prototype.toSnakeCase = function (
	this: string,
	textTransform: TextTransform = 'lowercase'
): string {
	if (!this) return this;

	const str = String(this)
		.replace(/([A-Z])/g, (match, group) => `_${group.toLowerCase().trim()}`)
		.replace(/ /, '')
		.replace(/^_/, '');

	return textTransform === 'uppercase' ? str.toUpperCase() : str.toLowerCase();
};

String.prototype.in = function (this: string, ...args: string[]): boolean {
	return !_.isEmpty(this) && args.includes(this);
};

String.prototype.notIn = function (this: string, ...args: string[]): boolean {
	return !(!_.isEmpty(this) && args.includes(this));
};

String.prototype.equals = function (
	this: string,
	other: string,
	ignoreSpaces?: boolean,
	ignoreCase?: boolean
): boolean {
	let current = this;
	ignoreSpaces = ignoreSpaces || false;
	ignoreCase = ignoreCase || false;

	if (ignoreCase) {
		current = current.toLocaleLowerCase();
		other = other.toLocaleLowerCase();
	}

	if (ignoreSpaces) {
		current = current.trim();
		other = other.trim();
	}

	return _.isEqual(current, other);
};

String.prototype.notEquals = function (
	this: string,
	other: string,
	ignoreSpaces: boolean,
	ignoreCase: boolean
): boolean {
	return !this.equals(other, ignoreSpaces, ignoreCase);
};

export {};
