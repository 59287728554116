declare global {
    interface Number {
        repeat(startWith?: number): number[];
    }
}

Number.prototype.repeat = function(this: number, startWith: number = 0): number[] {
    if (!this) return [];
    
    let num = this;

    if (startWith > 0) {
        num = num + startWith; 
        const arr = [ ...Array(num).keys() ];
        return arr.splice(startWith);
    }

    return [ ...Array(num).keys() ];
};

export {}